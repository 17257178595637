import React, { useState } from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from "@material-ui/core";
import globalMessages from "../../messages";
import { useIntl } from "react-intl";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import {useUserProvider} from "../../providers/UserProvider";

interface TextInfoDialogProps {
  title: string;
  children: React.ReactNode;
}

export const TextInfoDialog: React.FC<TextInfoDialogProps> = ({ title, children }) => {
  const {user} = useUserProvider();
  const {formatMessage} = useIntl();
  
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box style= {{display:"flex", alignItems:"center", justifyContent:"center"}}>
      <Box ml={1} display={'flex'} flexDirection={'row'} style = {{alignItems:"center", justifyContent:"center"}}>
        {user?.helpSection?.infoButtons &&
          <Box ml={1} onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
            <InfoOutlined style={{ fontSize: 20 }} />
          </Box>
        }
      </Box>
      
      <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage(globalMessages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
