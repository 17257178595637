import { Box, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { firestore } from '../../firebase';
import { Activity } from 'myfitworld-model';
import BirthdayNotificationView from './BirthdayNotificationView';
import ActivityView from './ActivityView';
import { useNotificationsProvider } from '../../providers/NotificationsProvider';
import LoadMoreButton from './LoadMoreButton';
import DeleteUserNotificationView from './DeleteUserNotificationView';
import { useIntl } from 'react-intl';
import chatMessages from './messages';
import TrainerNotificationView from './TrainerNotificationView';
import { getUser } from 'src/api/usersApi';
import { NotificationWithClientData } from '../notifications/NotificationsDrawer';
import LoadingSpinner from '../LoadingSpinner';


const Notifications = ({userId, handleClose, navigation, onChangeTab, setIsLoadMore}: {userId: string,  handleClose: (event: any) => void, navigation: any, onChangeTab: (userId: string) => void, setIsLoadMore: (state: boolean) => void}) => {
    const {notificationsForEachClient, setCurrentLimit, setUserIdForLoad, currentLimit} = useNotificationsProvider();
    const {formatMessage} = useIntl();
    const [notificationsWithClientData, setNotificationsWithClientData] = useState<NotificationWithClientData[]>([]);
    const [loading, setLoading] = useState(true);

    const onViewDetails = useCallback(
        async (event: any, activity: Activity) => {
            await firestore.doc(`/activity/${activity.id}`).update({read: true});
            navigation(`/clients/${activity.threadId}/statistics/${activity.workoutId}`);
            handleClose(event);
    },[handleClose, navigation]);

    const loadMoreNotifications = () => {
        setIsLoadMore(true);
        setCurrentLimit((prevState: number) => prevState + 10);
        setUserIdForLoad(userId);
    }

    const loadClientsForEachNotification = async () => {
        const updatedNotifications = await Promise.all(
          notificationsForEachClient[userId].notifications.map(async (notification) => {
            const client = await getUser(notification.threadId);
            return {
              ...notification,
              client,
            } as NotificationWithClientData;
          })
        );
        setNotificationsWithClientData(updatedNotifications);
        setLoading(false);
    };

    useEffect(() => {
        if(notificationsForEachClient[userId] !== undefined){
            loadClientsForEachNotification();
        }
    }, [notificationsForEachClient]);

    return(
        <Box>
            {loading &&
            <LoadingSpinner></LoadingSpinner>
            }
            {
                !loading && notificationsForEachClient[userId].notifications.length === 0 && 
                <Typography variant='h5' style={{textAlign: 'center', marginTop: 20}}>{formatMessage(chatMessages.not_have_notifications_for_selected_user)}</Typography>
            }
            {
                notificationsForEachClient[userId].notifications.length >= currentLimit && 
                <LoadMoreButton onPress={loadMoreNotifications}/>
            }
            {notificationsWithClientData.reverse().map((msg) => (
                <>
                    {msg.type === "BIRTHDAY_NOTIFICATION" &&
                        <BirthdayNotificationView 
                            activity={msg} 
                            key={msg.id} 
                            onSendHappyBirthday={() => onChangeTab(userId)}
                            client={msg.client}
                        />
                    }  
                    {
                        msg.type === "ACTIVITY" && 
                        <ActivityView 
                            activity={msg} 
                            key={msg.id} 
                            onViewDetails={(event: any) => onViewDetails(event, msg)}
                            client={msg.client}
                        />
                    }
                    {
                        msg.type === "DELETE_USER" && 
                        <DeleteUserNotificationView 
                            activity={msg} 
                        />
                    }
                    {
                        msg.type === "TRAINER_NOTIFICATION" && 
                        <TrainerNotificationView 
                            activity={msg}
                            client={msg.client}
                        />
                    }
                </>
            )
            )}
            <div style={{overflowAnchor: 'auto'}}></div>
        </Box>
    );

};
export default Notifications;