import React, {useEffect, useRef, useState} from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Activity, User } from "myfitworld-model";
import ActivityType from "myfitworld-model/dist/enums/ActivityType";
import { useUserProvider } from "../../providers/UserProvider";
import SendIcon from "@material-ui/icons/Send";
import useNavigation from "../../hooks/useNavigation";
import { dropdownMobileTabStyle } from "../../pages/globalPagesStyles";
import Notifications from "./Notifications";
import { useMessagesContext } from "../../providers/MessagesProvider";
import { useNotificationsProvider } from "../../providers/NotificationsProvider";
import { useMfwThemeProvider } from "myfitworld-utils";
import { useIntl } from "react-intl";
import chatMessages from "./messages";
import MessagesView from "./MessagesView";

const LIMIT_MESSAGES = 6;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      zIndex:1000,
      minHeight:"50px"
    },
    tab: {
      minHeight: 45,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    dropdownMobileTabStyle: dropdownMobileTabStyle(theme),
    exitButton:{
      color: theme.palette.text.primary
    }
  })
);
const ChatDialog = ({ isOpen, handleClose, userId, userName, ignoreTabs }: Props) => {
  const classes = useStyles();
  const { user } = useUserProvider();
  const theme = useMfwThemeProvider();
  const { navigation } = useNavigation();
  const {formatMessage} = useIntl();
  const messagesCtx = useMessagesContext();
  const {markAsReadNotificationsForClient} = useNotificationsProvider();
  const dialogRef = useRef<HTMLDivElement | null>(null);

  const [message, setMessage] = useState<string>("");
  const [scroll, setScroll] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);
  
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const submitButtonRf = useRef<HTMLButtonElement>(null);

  const onSendMessage = async () => {
    if (!user) return;
    messagesCtx.saveMessage({
      threadId: userId,
      senderId: user?.id,
      text: message,
      watchers: [userId, user?.id],
      read: false,
      type: ActivityType.MESSAGE,
      createdAt: new Date()
    } as Activity);
    setMessage('');
  };

  const [value, setValue] = useState(0);

  useEffect(() => {
    value === 0 ? messagesCtx.markAsReadMessagesForClient(userId) : markAsReadNotificationsForClient(userId);
  }, [value]);

  useEffect(() => {
    const msgDialog = document.getElementById("messagesDialog");
    if (msgDialog && !isLoadMore) {
      msgDialog.scrollTo(0, msgDialog.scrollHeight);
    }
    setIsLoadMore(false);
  }, [messagesCtx.messagesForEachClient, scroll]);

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.scrollTop = dialogRef.current.scrollHeight;
    }
  }, [dialogRef]);

  return (
    <Dialog
      aria-labelledby="chat-dialog"
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClick={(event) => event.stopPropagation()}
    >
      <Box>
        <Box className={classes.title}>
          <DialogTitle>{userName}</DialogTitle> 
          <Box style={{margin: 5}}>
            <IconButton className={classes.exitButton} onClick={(event) => {dialogRef.current = null; handleClose(event)}} size={"medium"}>
              <CloseIcon/>
            </IconButton>
          </Box>
        </Box>
       
        {!ignoreTabs && 
          <Box flexDirection='row'>
            <Tabs
                centered
                value={value}
                onChange={(event, newValue) => {setValue(newValue); setScroll(true); setIsLoadMore(false);}}
                aria-label='Content navigation'
                textColor='primary'
              >
                <Tab 
                  label="Chat"
                  className={classes.tab}
                ></Tab>
                 <Tab 
                    label="Notifications"
                    className={classes.tab}
                ></Tab>
              </Tabs>
          </Box>
        }
      </Box>
       <DialogContent id="messagesDialog" ref={dialogRef} style={{height: 500}}>
          {
            value === 0 && 
              <MessagesView userId={userId} setIsLoadMore={setIsLoadMore}/> 
          }
          {
            value === 1 && 
            <Notifications userId={userId} handleClose={handleClose} navigation={navigation} onChangeTab={(userId: string) => setValue(0)} setIsLoadMore={setIsLoadMore}/>
          }
        </DialogContent>
        <DialogActions>
          {value === 0 && 
            <>
              <TextField
                onFocus={() => messagesCtx.markAsReadMessagesForClient(userId)}
                style={{flex: 1}}
                value={message}
                placeholder={formatMessage(chatMessages.chat_type_message)}
                onChange={(event) => setMessage(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    onSendMessage();
                  }
                }}
                multiline = {true}
                maxRows={5}
                inputRef={inputRef}
              />
              <IconButton
                onClick={onSendMessage}
                style={{color: message === '' ? theme.mfwMuiWebTheme.palette.primary.light : theme.mfwMuiWebTheme.palette.primary.main}}
                ref={submitButtonRf}
                disabled={message === ''}
              >
                <SendIcon fontSize='large'/>
              </IconButton>
            </>
          }
        </DialogActions>
    </Dialog>
  );
};

export default ChatDialog;

interface Props {
  isOpen: boolean;
  handleClose: (event: any) => void;
  userId: string;
  userName?: string;
  ignoreTabs?: boolean;
}


const getUserName = (user: User) => {
  let username = "";
  if (user.firstName) username += user.firstName;
  if (user.lastName) {
    username += user.lastName;
    return username;
  }
  if (user.firstName || user.lastName) username = `${user.firstName || ""} ${user.lastName || ""}`;
  else if (user.email) username = user.email.split("@")[0];
  else if (user.phoneNumber) username = user.phoneNumber;
  else username = "Unknown";
  return username;
};
