import { Invitation } from "myfitworld-model";
import { firestore } from "../firebase";
import firebase from "firebase";
export const getInvitation = async (email: string) => {

    try {
        const invitationDocs = await firestore.collection('invitations')
        .where('email', '==', email)
        .where('acceptedOn', '==', null)
        .where('rejected', '==', false)
        .get();
        if(invitationDocs.docs.length > 0) {
            return {
                ...invitationDocs.docs[0].data(),
                id: invitationDocs.docs[0].id 
              } as Invitation;
              
        }

        return undefined;
    } catch (error) {
        console.log(error);
      throw error;
    }
};

export const acceptInvitation = async (invitation: Invitation, userId: string) => {
    try {
        const invitationDoc = firestore.collection('invitations').doc(invitation.id);
        const nowTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
        invitationDoc.update({
            acceptedOn: nowTimestamp
        });

        const userRef = firestore.collection("users").doc(userId);
        const newOrganization = {id: invitation.organization, role: invitation.role, archived: false};
        const doc = await userRef.get();
        const user = doc.data();
        let organizations = user?.organizations;
        let oldOrganization = organizations[organizations.length - 1];
        oldOrganization.archived = true;
        organizations.push(newOrganization);
        await userRef.update({
            currentOrganization: invitation.organization,
            organizations: organizations,
            hasInvitation: false
        });
        const orgUserRef = firestore.collection("organizationUser").doc(`${userId}-${invitation.organization}`);
        await orgUserRef.update({
            invitationAccepted: true
        });
        if(invitation.role === 'Client'){
            await userRef.update({
                trainers: firebase.firestore.FieldValue.arrayUnion(invitation.invitedBy)
            });
        }

    } catch (error) {
      throw error;
    }
}   

export const rejectInvitation = async (id: string, userId: string) => {
    try {
        const invitationDoc = firestore.collection('invitations').doc(id);
        invitationDoc.update({
            rejected: true
        });
        const userRef = firestore.collection("users").doc(userId);
        await userRef.update({
            hasInvitation: false
        });
    } catch (error) {
      throw error;
    }
}  
  