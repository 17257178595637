import { useState, useEffect, useCallback } from 'react';
import { Invitation, User } from 'myfitworld-model';
import { acceptInvitation, getInvitation, rejectInvitation } from '../api/invitation';

export const useInvitation = ({ user }: { user: User | null }) => {
  const [invitation, setInvitation] = useState<Invitation>();

  const fetchInvitation = useCallback(async () => {
    if (!user || !user.email) return;
    console.log('Fetching invitation for user:', user.email);

    try {
      const data = await getInvitation(user.email);
      setInvitation(data);
    } catch (error) {
      console.error('Failed to fetch invitation:', error);
    }
  }, [user]);

  const updateInvitation = useCallback(
    async (action: 'accept' | 'reject', userId: string | undefined) => {
      if (!(invitation && invitation.id) || !userId) return;

      try {
        if (action === 'accept') {
          await acceptInvitation(invitation, userId);
        } else {
          await rejectInvitation(invitation.id, userId);
        }
        await fetchInvitation();
      } catch (error) {
        console.error('Failed to update invitation:', error);
        throw error;
      }
    },
    [invitation, fetchInvitation]
  );

  useEffect(() => {
    fetchInvitation();
  }, [fetchInvitation]);

  return { invitation, setInvitation, updateInvitation, refetch: fetchInvitation };
};
