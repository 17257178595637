import { Workout, WorkoutDay } from "myfitworld-model";
import { firestore } from "../firebase";


export const getWorkout = async(workoutId: string) => {
    const doc = await firestore.collection("workouts").doc(workoutId).get();

    return doc.exists ? doc.data() as Workout : null;
};

export const addNewWorkout = async(workout: Workout, isWhiteLabel: boolean) => {
    try{
        return new Promise(async (resolve) => {
            await firestore.collection("workouts").add({
                ...workout,
                isWhiteLabel
            })
                .then(() => resolve(true))
        });
    } catch(error) {
        throw error;
    }
}

export const deleteWorkoutsForClient = async(userId: string, orgId: string) => {
    firestore
      .collectionGroup('assignedWorkouts')
      .where('userId', '==', userId)
      .get()
      .then((workoutsQuery) => {
        const workouts: WorkoutDay[] = [];

        workoutsQuery.docs.forEach(val => {
          const raw = val.data();
          workouts.push({
            ...raw as unknown as WorkoutDay,
            parentId: val.ref.parent.parent?.id
          })
        });

        // @ts-ignore
        const filteredWorkouts = workouts.filter((workout: any) => {
          if (workout.generatedBy === undefined) {
            return workout.workout.organizationId === orgId;
          } else if (workout.generatedBy !== 'CLIENT') {
            return workout.workout.organizationId === orgId;
          }
        });

        // Deleting User assignedWorkouts that belongs to Organization
        filteredWorkouts.forEach((doc) => {
          firestore
            .collection(`users/${userId}/assignedPrograms/${doc.parentId}/assignedWorkouts/`)
            .doc(doc.id)
            .delete()
            .catch(error => {
              console.error(error.message);
            });
        });
    })
    .catch(error => {
    console.error(error.message);
    });
}