import React, { Fragment } from 'react';
import {Box, Button, createStyles, makeStyles, Typography, useMediaQuery} from '@material-ui/core';
import {configMainRoutesConsts} from "../../config/route";
import {getVideoSourceByAppSection} from "../../utils/getVideoSourceTutorial";
import {SectionOptions} from "../../components/users/helpers";
import componentMessages from "../../components/messages";
import {TextInfoDialog} from "./TextInfoDialog";
import {TutorialInfoDialog} from "./TutorialInfoDialog";
import {StringStyleParser} from "../../utils/StringStyleParser";
import {useIntl} from "react-intl";
import {useMfwThemeProvider} from "myfitworld-utils";
import useEntityList from "../../hooks/useEntityList";
import {Tutorial} from "myfitworld-model";
import {tutorialApi} from "../../api/common";
import {useUserProvider} from "../../providers/UserProvider";
import messages from "../../components/notifications/messages";
import {CALENDLY_LINK} from "../../components/notifications/HelpSectionDrawer";

const useStyles = makeStyles(() =>
  createStyles({
    subtext: {
      marginBottom: 5,
      fontWeight: 'bold'
    },
    typographyMargin: {
      marginTop: 10
    },
    typographyMarginBottom: {
      marginBottom: 10
    }
  }),
);

const SectionTitleWithTextTutorialsContent = (pathname: string) => {
  const {user} = useUserProvider();
  const {formatMessage} = useIntl();
  const theme = useMfwThemeProvider();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.mfwMuiWebTheme.breakpoints.down("sm"));
  const variantTypography = isMobile ? 'h6' : 'h4';
  const customStyle: React.CSSProperties = { fontWeight: 'bold' };

  const {data: tutorials} = useEntityList<Tutorial>(tutorialApi.list);

  const renderContent = () => {
    switch (true) {
      case pathname.includes(configMainRoutesConsts.schedule): {
        const scheduleVideoSource = getVideoSourceByAppSection(tutorials, SectionOptions[1].id, user?.language);

        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.my_schedule)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.my_schedule)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.schedule_info_text1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.schedule_info_text2)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.schedule_info_text3)}</Typography>
              <Typography className={classes.typographyMargin}>{formatMessage(componentMessages.schedule_calendly_link_text)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
            <TutorialInfoDialog title={formatMessage(componentMessages.my_schedule)} videoSource={scheduleVideoSource} />
          </Box>
        );
      }
      case pathname.includes(configMainRoutesConsts.content):
        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.content)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.content)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.libraryInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.libraryInfoText2)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.libraryInfoText3)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.libraryInfoText4)}</Typography>
              <Typography className={classes.typographyMargin}>{formatMessage(componentMessages.schedule_calendly_link_text)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.nutritionPlan):
        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.nutrition)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.nutrition)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.nutritionInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.nutritionInfoText2)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.nutritionInfoText3)}</Typography>
              <Typography className={classes.typographyMargin}>{formatMessage(componentMessages.schedule_calendly_link_text)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.clients):
        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.clients)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.clients)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.clientsInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.clientsInfoText2)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.clientsInfoText3)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.clientsInfoText4)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.clientsInfoText5)}</Typography>
              <Typography className={classes.typographyMargin}>{formatMessage(componentMessages.schedule_calendly_link_text)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.profile):
        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.profile)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.profile)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.profileInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.profileInfoText2)}</Typography>
              <Typography className={classes.typographyMargin}>{formatMessage(componentMessages.schedule_calendly_link_text)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.configuration):
        const configurationVideoSource = getVideoSourceByAppSection(tutorials, SectionOptions[8].id, user?.language);

        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.configuration)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.configuration)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.configurationInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.configurationInfoText2)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.configurationInfoText3)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.configurationInfoText4)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
            <TutorialInfoDialog title={formatMessage(componentMessages.configuration)} videoSource={configurationVideoSource} />
          </Box>
        );
      case pathname === configMainRoutesConsts.organization:
        const myOrganizationVideoSource = getVideoSourceByAppSection(tutorials, SectionOptions[9].id, user?.language);

        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.myOrganization)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.myOrganization)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.myOrganizationInfoTextList1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.myOrganizationInfoTextList2)}</Typography>
              <Box ml={2}>
                <ul>
                  <li>
                    <StringStyleParser text={formatMessage(componentMessages.myOrganizationInfoTextList3)} customStyle={customStyle}/>
                  </li>
                  <li>
                    <StringStyleParser text={formatMessage(componentMessages.myOrganizationInfoTextList4)} customStyle={customStyle}/>
                  </li>
                  <li>
                    <StringStyleParser text={formatMessage(componentMessages.myOrganizationInfoTextList5)} customStyle={customStyle}/>
                  </li>
                </ul>
              </Box>
              <Typography className={`${classes.typographyMarginBottom} ${classes.typographyMargin}`}>
                {formatMessage(componentMessages.myOrganizationInfoTextList6)}
              </Typography>
              <Typography className={classes.typographyMargin}>{formatMessage(componentMessages.schedule_calendly_link_text)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
            <TutorialInfoDialog title={formatMessage(componentMessages.myOrganization)} videoSource={myOrganizationVideoSource} />
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.orgProfile):
        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.organizationProfile)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.organizationProfile)}>
              <Typography className={classes.subtext}>{formatMessage(componentMessages.myOrganizationInfoTextSubTitle)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.organizationProfileInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.organizationProfileInfoText2)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.organizationProfileInfoText3)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.organizationProfileInfoText4)}</Typography>
              <Typography className={classes.typographyMargin}>{formatMessage(componentMessages.schedule_calendly_link_text)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.tutorials):
        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.tutorialVideos)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.tutorialVideos)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.tutorialVideosInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.tutorialVideosInfoText2)}</Typography>
              <Typography>{formatMessage(componentMessages.tutorialVideosInfoText3)}</Typography>
            </TextInfoDialog>
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.billing):
        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.billing)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.billing)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.billingInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.billingInfoText2)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.billingInfoText3)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.billingInfoText4)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.billingInfoText5)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.billingInfoText6)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.billingInfoText7)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.billingInfoText8)}</Typography>
              <Typography className={classes.typographyMargin}>{formatMessage(componentMessages.schedule_calendly_link_text)}</Typography>
              <Button
                style={{padding: 0}}
                color='primary' variant='text'
                onClick={() => window.open(CALENDLY_LINK, '_blank')}
              >
                {formatMessage(messages.schedule_free_consultations)}
              </Button>
            </TextInfoDialog>
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.stripeConnect):
        const stripeConnectVideoSource = getVideoSourceByAppSection(tutorials, SectionOptions[15].id, user?.language);
        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.stripeConnect)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.stripeConnect)}>
              <Box className={classes.typographyMarginBottom}>
                <StringStyleParser text={formatMessage(componentMessages.stripeConnectInfoText1)} customStyle={customStyle}/>
              </Box>
              <Typography>{formatMessage(componentMessages.stripeConnectInfoText2)}</Typography>
            </TextInfoDialog>
            <TutorialInfoDialog title={formatMessage(componentMessages.billing)} videoSource={stripeConnectVideoSource} />
          </Box>
        );
      case pathname.includes(configMainRoutesConsts.marketplaceWithoutLanguage):
        const marketplaceVideoSource = getVideoSourceByAppSection(tutorials, SectionOptions[16].id, user?.language);

        return (
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={variantTypography} component='h1' color='textSecondary'>{formatMessage(componentMessages.marketplace)}</Typography>
            <TextInfoDialog title={formatMessage(componentMessages.marketplace)}>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.marketplaceInfoText1)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.marketplaceInfoText2)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.marketplaceInfoText3)}</Typography>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.marketplaceInfoText4)}</Typography>
              <Box className={classes.typographyMarginBottom}>
                <StringStyleParser text={formatMessage(componentMessages.marketplaceInfoText5)} customStyle={customStyle}/>
              </Box>
              <Typography className={classes.typographyMarginBottom}>{formatMessage(componentMessages.marketplaceInfoText6)}</Typography>
            </TextInfoDialog>
            <TutorialInfoDialog title={formatMessage(componentMessages.marketplace)} videoSource={marketplaceVideoSource} />
          </Box>
        );
      default:
        return <Fragment />;
    }
  }

  return <>{renderContent()}</>;
};

export default SectionTitleWithTextTutorialsContent;
