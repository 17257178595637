import React, {Fragment, useEffect, useState} from "react";
import {SideDrawerProperties} from "../SideDrawer";
import {useUserProvider} from "../../providers/UserProvider";
import {Box, Button, Divider, Drawer, Typography, useMediaQuery} from "@material-ui/core";
import CustomMessageSideDrawer from "../CustomMessageSideDrawer";
import { useIntl } from "react-intl";
import CloseIcon from "@material-ui/icons/Close";
import generalMessages from "../../messages";
import {useDrawerStyles} from "../../theme/useDrawerStyles";
import {useMfwThemeProvider} from "myfitworld-utils";
import {Controller, useForm} from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import {UnpackNestedValue} from "react-hook-form/dist/types/form";
import globalMessages from "../../messages";
import ContactSupportForm from "./ContactSupportForm";
import {updateUserDocument} from "../../api/usersApi";
import {User} from "myfitworld-model/src";
import SubtleInfoMessage from "../SubtleInfoMessage";
import messages from "./messages";
import {isStageEnv} from "../../App";

interface HelpSectionValues extends User {
  infoButtons: boolean,
  tutorialButtons: boolean
}

export const CALENDLY_LINK = "https://calendly.com/milan-sundac/myfitworld-introduction-clone-3";

const HelpSectionDrawer = ({ isOpen, onClose }: Props) => {
  const { user } = useUserProvider();
  const {formatMessage} = useIntl();
  const {mfwMuiWebTheme} = useMfwThemeProvider();
  const isMobile = useMediaQuery(mfwMuiWebTheme.breakpoints.down('sm'));
  const classes = useDrawerStyles(mfwMuiWebTheme, isMobile);
  const drawerClasses = classes();

  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
  }, [user]);

  const {control, handleSubmit, formState: {isDirty}} = useForm<HelpSectionValues>({
    criteriaMode: 'all',
    defaultValues: {
      ...user,
      infoButtons: user?.helpSection?.infoButtons || false,
      tutorialButtons: user?.helpSection?.tutorialButtons || false
    }
  });

  const onSubmitHandler = handleSubmit((helpSection: UnpackNestedValue<HelpSectionValues>) => {
    try {
      setLoading(true);
      const storeData = {...user, helpSection};
      
      updateUserDocument(storeData)
        .then(() => {
          setLoading(false);
          onClose();
        });
    } catch (error) {
      setLoading(false);
      console.error('Error Updating User', error);
    }
  });
  
  return (
    <Fragment>
      <CustomMessageSideDrawer 
        isOpen={isOpen} 
        onClose={onClose} 
        showArchived={showArchived} 
        setShowArchived={setShowArchived}
      >
        <Drawer classes={{paper: drawerClasses.drawerPaper}} variant="persistent" anchor="right" open={isOpen}>
          <Box className={drawerClasses.drawerHeader}>
            <Button
              size="small"
              variant="outlined"
              startIcon={<CloseIcon/>}
              onClick={_ => onClose()}
              disabled={isLoading}
            >
              {formatMessage(generalMessages.close)}
            </Button>
          </Box>
          
          <Box mb={2}>
            <Typography variant='h4' gutterBottom>{formatMessage(messages.help_section)}</Typography>
          </Box>
          <SubtleInfoMessage>
            {formatMessage(messages.when_switch_on)}
          </SubtleInfoMessage>
          <form onSubmit={onSubmitHandler}>
            <Box display='flex' flexDirection='row' alignItems='center' mt={1} mb={1}>
              <Controller
                name='infoButtons'
                control={control}
                render={({onChange, value}) => {
                  return (
                    <Switch
                      name='infoButtons'
                      color='primary'
                      checked={value}
                      onChange={(_, checked) => onChange(checked)}
                    />
                  )
                }}
              />
              <Typography color='textPrimary'>{formatMessage(messages.info_buttons)}</Typography>
            </Box>

            {isStageEnv &&
              <Box display='flex' flexDirection='row' alignItems='center' mt={1} mb={1}>
                <Controller
                  name='tutorialButtons'
                  control={control}
                  render={({onChange, value}) => {
                    return (
                      <Switch
                        name='tutorialButtons'
                        color='primary'
                        checked={value}
                        onChange={(_, checked) => onChange(checked)}
                      />
                    )
                  }}
                />
                <Typography color='textPrimary'>{formatMessage(messages.info_tutorials)}</Typography>
              </Box>
            }

            <Box mt={2}>
              <Button
                color='primary'
                variant='contained'
                onClick={onSubmitHandler}
                disabled={isLoading || !isDirty}
              >
                {formatMessage(globalMessages.apply)}
              </Button>
            </Box>
          </form>

          <Box mt={2} mb={2}>
            <Divider variant="middle" />
          </Box>

          <Box>
            <Typography variant='h4' gutterBottom>{formatMessage(messages.schedule_consultations)}</Typography>
          </Box>

          <Box>
            <SubtleInfoMessage>
              {formatMessage(messages.info_schedule_consultations)}
            </SubtleInfoMessage>
            <Button
              color="primary"
              variant="text"
              style={{ marginTop: 10 }}
              disabled={isLoading}
              onClick={() => {
                window.open(CALENDLY_LINK, '_blank');
              }}
            >
              {formatMessage(messages.schedule_free_consultations)}
            </Button>
          </Box>

          <Box mt={2}>
            <Divider variant="middle" />
          </Box>

          <Box mt={2} mb={2}>
            <Typography variant='h4' gutterBottom> {formatMessage(messages.contact_support)}</Typography>
          </Box>

          <ContactSupportForm />
        </Drawer>
      </CustomMessageSideDrawer>
    </Fragment>
  );
};

export default HelpSectionDrawer;

interface Props extends SideDrawerProperties {}
