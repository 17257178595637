import React, { useState } from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography,} from "@material-ui/core";
import globalMessages from "../../messages";
import { useIntl } from "react-intl";
import LiveTvRoundedIcon from '@material-ui/icons/LiveTvRounded';
import {useUserProvider} from "../../providers/UserProvider";
import {useMfwThemeProvider} from "myfitworld-utils";
import {formatYoutubeLink} from "../universalFunctions";
import componentMessages from "../../components/messages";

interface TutorialInfoDialogProps {
  title: string;
  videoSource?: string | undefined;
  mlStyle?: number
}

export const TutorialInfoDialog: React.FC<TutorialInfoDialogProps> = ({ title, videoSource, mlStyle }) => {
  const {user} = useUserProvider();
  const {formatMessage} = useIntl();
  const theme = useMfwThemeProvider();
  const marginLeftStyle = mlStyle || 1;
  
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Box ml={1} display={'flex'} flexDirection={'row'} style={{alignItems:"center", justifyContent:"center"}}>
        {user?.helpSection?.tutorialButtons &&
          <Box ml={marginLeftStyle} onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
            <LiveTvRoundedIcon style={{ fontSize: 20, transform: 'rotate(180deg)', marginTop: 2, fill: theme.theme.theme.primary}} />
          </Box>
        }
      </Box>
      
      <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        
        <DialogContent>
          <DialogContentText>
            {videoSource ?
              <Box>
                <iframe style={{height: 300, width: '100%'}} src={formatYoutubeLink(videoSource)}/>
              </Box>
              :
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
                <Typography>{formatMessage(componentMessages.videoUnavailable)}</Typography>
              </Box>
            }
          </DialogContentText>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage(globalMessages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
