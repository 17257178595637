
import { Activity,  } from "myfitworld-model";
import { updateActivityUnlinkQuestion } from "src/api/activityApi";
import { deleteWorkoutsForClient } from "src/api/workoutsApi";

function useKeepClientWorkouts() {
    const confirmKeepWorkouts = async (activity: Activity) => {
        await updateActivityUnlinkQuestion(activity, 'accepted');
    }

    const rejectKeepWorkouts = async (activity: Activity, orgId: string) => {
        await updateActivityUnlinkQuestion(activity, 'rejected');
        deleteWorkoutsForClient(activity.senderId, orgId);
    }

    return {
        confirmKeepWorkouts,
        rejectKeepWorkouts
    };
}
export default useKeepClientWorkouts;
