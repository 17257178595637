import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    the_invitation_has_been_sent_to_the_email: {
        id: 'messages.the_invitation_has_been_sent_to_the_email',
        defaultMessage: 'Connection request successfully sent to the email'
    },
    invalid_email: {
        id: 'invalid_email',
        defaultMessage: 'Invalid Email'
    },
    this_email_is_not_exist: {
        id: 'messages.this_email_is_not_exist',
        defaultMessage: 'User not found - please check if the client has registered to the MyFitWorld mobile App.'
    },
    this_email_is_not_exist_web: {
        id: 'messages.this_email_is_not_exist_web',
        defaultMessage: 'User not found - please check if the user has registered to the MyFitWorld web App.'
    },
    this_email_is_already_connected_to_your_organization: {
        id: 'messages.this_email_is_already_connected_to_your_organization',
        defaultMessage: 'This email is already connected to your organization.'
    },
    this_email_is_already_invited_from_your_organization: {
        id: 'messages.this_email_is_already_invited_from_your_organization',
        defaultMessage: 'This email is already invited from your organization.'
    },
    this_email_is_already_connected_to_another_organization: {
        id: 'messages.this_email_is_already_connected_to_another_organization',
        defaultMessage: 'This email is already connected to another organization.'
    },
    only_letters_are_allowed: {
        id: 'messages.only_letters_are_allowed',
        defaultMessage: 'Only letters are allowed'
    },
    create_client_help_text: {
        id: 'invitation.create_client_help_text',
        defaultMessage: 'This action will create a new client profile in your pending profile list. When you create a client, automated connection request will be sent to the user with entered email - your client needs to confirm the connection request.',
    },
    check_spam: {
        id: 'invitation.check_spam',
        defaultMessage: 'The invitations to the client are sent via email. Please make sure your client checks the spam/junk section.'
    },
    check_spam2: {
        id: 'invitation.check_spam2',
        defaultMessage: 'You can also send the link directly after copying it from the "Connection Link".'
    },
    create_client_spam_text: {
        id: 'invitation.create_client_spam_text',
        defaultMessage: 'In case your client does not see the email, please double check spam/junk mail.'
    },
    create_client_help_text_bold: {
        id: 'invitation.create_client_help_text_bold',
        defaultMessage: 'By creating a client, you increase the number of clients on your subscription plan. If you archive or remove a client from the pending or active list, you decrease the number of clients on your subscription plan.'
    },
    invitation_disabled: {
        id: 'invitation.invitation_disabled',
        defaultMessage: 'If you cannot send the invitation, contact your organization admin.'
    },
    sending_connection_request: {
        id: 'messages.sending_connection_request',
        defaultMessage: 'Sending connection request'
    },
    admin: {
        id: 'role.admin',
        defaultMessage: 'Admin'
    },
    assistanttrainer: {
        id: 'role.assistanttrainer',
        defaultMessage: 'Assistant Trainer'
    },
    client: {
        id: 'role.client',
        defaultMessage: 'Client'
    },
    trainer: {
        id: 'role.trainer',
        defaultMessage: 'Trainer'
    },
    add_client: {
        id: 'button.add_client',
        defaultMessage: ' + Add Client'
    },
    create_member: {
        id: 'Create member',
        defaultMessage: 'Create Member'
    },
    are_you_sure_you_want_to_remove: {
        id: 'are_you_sure_you_want_to_remove',
        defaultMessage: 'Are you sure you want to remove {name} from the pending list?'
    },
    free_clients_limit: {
        id: 'invitation.free_clients_limit',
        defaultMessage: 'By adding one more client you are going to exceed the limit of 2 free clients.'
    },
    invitation_sent_question: {
        id: 'invitation_sent_question',
        defaultMessage: 'Connection Requested?'
    },
    connection_link: {
        id: 'Link',
        defaultMessage: 'Connection Link'
    },
    pending: {
        id: 'Pending',
        defaultMessage: 'Pending'
    },
    invitation_sent: {
        id: 'messages.invitation_sent',
        defaultMessage: 'Connection request sent.'
    },
    copy_link: {
        id: 'Copy link',
        defaultMessage: 'Copy link'
    },
    dont_have_invitations: {
        id: 'dont_have_invitations',
        defaultMessage: "You don't have any invited clients now."
    },
    dont_have_members: {
        id: 'dont_have_invitations',
        defaultMessage: "You don't have any invited members now."
    },
    rejected: {
        id: 'rejected',
        defaultMessage: 'REJECTED'
    },
    send_again: {
        id: 'send_again',
        defaultMessage: 'SEND AGAIN'
    },
    status: {
        id: 'status',
        defaultMessage: 'Status'
    },
    client_account: {
        id: 'messages.client_account',
        defaultMessage: "This email is registered like client's account."
    }
})
export default messages;
