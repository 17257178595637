import { OrganizationUser } from "myfitworld-model";
import { firestore } from "../../../firebase";

export type TrainerListItem = {
  id: string;
  name: string;
};

export type OrganizationErrorType = "notFound" | "organizationNotFound" | "noTrainers" | "noMembers";
export class OrganizationError extends Error {
  private _type: OrganizationErrorType;

  constructor(message: string, type: OrganizationErrorType) {
    super(message);
    this._type = type;
  }
  get type() {
    return this._type;
  }
}
const organizationUserPath: string = "organizationUser";

export const fetchOrganizationTrainerList = async ({ organizationId }: { organizationId: string }) => {
  let trainers: TrainerListItem[] = [];
  try {
    const organizationDocs = await firestore
      .collection(organizationUserPath)
      .where("orgId", "==", organizationId)
      .where("role", "in", ["Admin", "Trainer"])
      .get();

    if (!organizationDocs) {
      throw new OrganizationError(
        `${organizationUserPath}/${organizationId} not found or data is undefined!`,
        "noTrainers"
      );
    }

    organizationDocs.docs.map((doc) => {
      const data = doc.data();
      if (data && data.user) {
        const trainerId = data.userId;
        const name = `${data.user.firstName} ${data.user.lastName}`;

        trainers.push({ id: trainerId, name: name });
      }
    });

    return trainers;
  } catch (error) {
    throw error;
  }
};

export const fetchOrganizationMembers = async ({ organizationId, isArchived, isAdmin }: { organizationId: string , isArchived:boolean, isAdmin:boolean}) => {
  let members: OrganizationUser[] = [];
  try {
    let baseQuery =  firestore
    .collection(organizationUserPath)
    .where("orgId", "==", organizationId)
    .where("archived", "==", isArchived)
    .where("invitationAccepted", "==", true);
    
    if(isAdmin){
      baseQuery = baseQuery.where("role", '==', "Admin")
    } else baseQuery = baseQuery.where("role", "in", ["Trainer","AssistantTrainer"])

    const organizationDocs = await baseQuery.get();

    if (!organizationDocs) {
      throw new OrganizationError(
        `${organizationUserPath}/${organizationId} not found or data is undefined!`,
        "noMembers"
      );
    }

    organizationDocs.docs.map((doc) => {
      const data = doc.data() as OrganizationUser;
      if (data && data.user) {
        members.push(data);
      }
    });

    return members;
  } catch (error) {
    console.log(error);
    throw error;
  }
};