import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  error_delete_Organization_Client: {
    id: 'error.deleteOrganizationClient',
    defaultMessage: 'Error deleting organization client!'
  },
  error_loading_Organization_Clients: {
    id: 'error.loadingOrganizationClients',
    defaultMessage: 'Error loading organization clients!'
  },
  error_loading_Organization_Memebers: {
    id: 'error.loadingOrganizationMembers',
    defaultMessage: 'Error loading organization members!'
  },
  error_loading_Organization_TrainerList: {
    id: 'error.loadingOrganizationTrainerList',
    defaultMessage: 'Error loading organizations trainer list!'
  },
  error_updating_Organizations_In_User: {
    id: 'error.loadingOrganizationsInUser',
    defaultMessage: 'Error updating organizations in user!'
  },
  error_saving_data: {
    id: 'error.saving_data',
    defaultMessage: 'Error saving data!'
  },
  error_expired_card: {
    id: 'error.expired_card',
    defaultMessage: 'The card has expired.'
  },
  error_incorrect_cvc: {
    id: 'error.incorrect_cvc',
    defaultMessage: 'The card has incorrect cvc.'
  },
  error_stolen_card: {
    id: 'error.stolen_card',
    defaultMessage: 'The payment was declined because the card is reported stolen.'
  },
  error_invalid_number: {
    id: 'error.invalid_number',
    defaultMessage: 'The card number is incorrect.'
  },
  error_insufficient_funds: {
    id: 'error.insufficient_funds',
    defaultMessage: 'The card has insufficient funds to complete the purchase.'
  },
  error_lost_card: {
    id: 'error.lost_card',
    defaultMessage: 'The payment was declined because the card is reported lost.'
  },
  error_payment_failed_code: {
    id: 'error.error_payment_failed_code',
    defaultMessage: 'Payment declined:'
  },
  payment_failed: {
    id: 'error.payment_failed',
    defaultMessage: 'Unfortunately, your payment could not be processed at this time. Please try again, and if the issue continues, feel free to reach out to our support team for assistance. We\'re here to help resolve any problems and ensure your transaction goes through smoothly.'
  },
  success_enabled_mfwnet: {
    id: 'success.enabled_mfwnet',
    defaultMessage: 'MFWNet is enabled successfully!'
  },
  success_disabled_mfwnet: {
    id: 'success.disabled_mfwnet',
    defaultMessage: 'MFWNet is disabled successfully!'
  },
  rebrand_subscription_cancelled: {
    id: 'rebrand_subscription_canceled',
    defaultMessage: 'Rebrand Subscription Successfully Cancelled'
  },
  error_creating_client_group: {
    id: 'error.creating_client_group',
    defaultMessage: 'Error creating client group!'
  },
  error_updating_client_group: {
    id: 'error.updating_client_group',
    defaultMessage: 'Error updating client group!'
  },
  error_deleting_client_group: {
    id: 'error.deleting_client_group',
    defaultMessage: 'Error deleting client group!'
  }
})

export default messages;
