import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    create_corp_organization: {
        id: 'corporate.create_corp_organization',
        defaultMessage: 'Create Corporation'
    },
    update_corp_organization: {
        id: 'corporate.update_corp_organization',
        defaultMessage: 'Update Corporation'
    },
    co_branding: {
        id: 'corporate.co_branding',
        defaultMessage: 'Co-Branding'
    },
    upload_custom_thumbnail: {
        id: 'Upload custom thumbnail',
        defaultMessage: 'Upload Custom Cover Photo'
    },
    choose_new_thumbnail: {
        id: 'Choose a new thumbnail',
        defaultMessage: 'Choose a New Cover Photo'
    },
    thumbnail_note: {
        id: 'content.thumbnail_note',
        defaultMessage: '"It may take a few minutes for the cover photo to update across all applications.'
    },
    corporate_color: {
        id: 'corporate.color',
        defaultMessage: 'Color'
    },
    number_of_clients: {
        id: 'Number of Clients',
        defaultMessage: 'Number of Clients'
    },
    paying_status: {
        id: 'paying_status',
        defaultMessage: 'Paying Status'
    },
    generate_user_codes: {
        id: 'corporate.generate_user_codes',
        defaultMessage: 'Generate user codes'
    },
    generate_user_codes_note: {
        id: 'corporate.generate_user_codes',
        defaultMessage: 'First you need to create a corporate organization and generating codes will be enabled on update corporation.'
    },
    activation_date: {
        id: 'corporate.activation_date',
        defaultMessage: 'Activation date'
    },
    disable_wl_net: {
        id: 'corporate.disable_WLnet',
        defaultMessage: 'Disable {netName}'
    },
    active_clients: {
        id: 'active_clients',
        defaultMessage: 'Active Clients'
    },
    freeze: {
        id: 'corporate.freeze',
        defaultMessage: 'Freeze'
    },
    unfreeze: {
        id: 'corporate.unfreeze',
        defaultMessage: 'Unfreeze'
    },
    freeze_corporation: {
        id: 'corporate.freeze_corporation',
        defaultMessage: 'Freeze Corporation'
    },
    unfreeze_corporation: {
        id: 'corporate.freeze_corporation',
        defaultMessage: 'Unfreeze Corporation'
    },
    freeze_dialog: {
        id: 'corporate.freeze_dialog',
        defaultMessage: 'Are you sure you want to FREEZE this corporation?'
    },
    unfreeze_dialog: {
        id: 'corporate.unfreeze_dialog',
        defaultMessage: 'Are you sure you want to UNFREEZE this corporation?'
    },
    corporate_id: {
        id: 'corporate.corporate_id',
        defaultMessage: 'Corporate ID'
    },
    corporation: {
        id: 'corporate.corporation',
        defaultMessage: 'Corporation'
    },
    create_organization: {
        id: 'create_organization',
        defaultMessage: 'Create Organization'
    },
    update_organization: {
        id: 'update_organization',
        defaultMessage: 'Update Organization'
    },
    free_use_app: {
        id: 'messages.free_use_app',
        defaultMessage: 'Free use of the application'
    },
    free_use_rebrand: {
      id: 'messages.free_use_rebrand',
      defaultMessage: 'Free use of Rebrand'
    },
    add_discount: {
        id: 'add_discount',
        defaultMessage: 'Add Discount'
    },
    add_rebrand_discount: {
      id: 'add_rebrand_discount',
      defaultMessage: 'Add Rebrand Discount'
    },
    discount: {
        id: 'Discount',
        defaultMessage: 'Discount'
    },
    rebrand_discount: {
      id: 'rebrand_discount',
      defaultMessage: 'Rebrand Discount'
    },
    alert: {
        id: 'Alert',
        defaultMessage: 'Alert'
    },
    add_alert: {
        id: 'add_alert',
        defaultMessage: 'Add Alert'
    },
    delete_organization: {
        id: 'organization.delete_organization',
        defaultMessage: 'Delete Organization'
    },
    delete_organization_message: {
        id: 'organization.delete_organization_message',
        defaultMessage: 'When you DELETE the certain organization make sure you really want to do this!' 
    },
    delete_action_cannot_be_undone_message: {
        id: 'organization.delete_action_cannot_be_undone_message',
        defaultMessage: 'This action CAN NOT BE UNDONE, all organization data including users and stripe data will be removed!'
    },
    organization_name: {
        id: 'organization.organization_name',
        defaultMessage: 'Organization Name'
    },
    organization_id: {
        id: 'organization.organization_id',
        defaultMessage: 'Organization ID'
    },
    to_delete: {
        id: 'organization.to_delete',
        defaultMessage: 'To delete'
    },
    organization_alert_message: {
        id: 'organization.organization_alert_message',
        defaultMessage: 'Please type confirm in the text field to be able to delete the organization!'
    },
    last_created_options_filter: {
        id: 'dateTimeFilter.last_created_options_filter',
        defaultMessage: 'Last Created At'
    },
    last_admin_organization_online_time: {
        id: 'last_admin_organization_online_time',
        defaultMessage: 'Last Active'
    },
    payment: {
        id: 'payment',
        defaultMessage: 'Payment'
    },
    go_back_to_super_admin_dashboard: {
        id: 'Go back to super admin dashboard',
        defaultMessage: 'Go back to super admin dashboard'
    },
    choose_organization: {
        id: 'choose_organization',
        defaultMessage: 'Choose organization'
    },
    download_active_clients: {
        id: 'download_active_clients',
        defaultMessage: 'Download active clients'
    },
    download_archived_clients: {
        id: 'download_archived_clients',
        defaultMessage: 'Download archived clients'
    },
    download_clients_without_trainer: {
        id: 'download_clients_without_trainer',
        defaultMessage: 'Download clients without trainer'
    },
    download_pending_clients: {
        id: 'download_pending_clients',
        defaultMessage: 'Download pending clients'
    },
    download_all_clients: {
        id: 'download_all_clients',
        defaultMessage: 'Download all clients'
    }
})

export default messages;
