import React, { useState } from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { Activity } from "myfitworld-model";
import { format } from "date-fns";
import {useMfwThemeProvider} from "myfitworld-utils";
import { useIntl } from "react-intl";
import chatMessages from './messages';

const useStyles = makeStyles((theme) => ({
    bubbleContainer: {
      width: "100%",
    },
    flexColumn: {
      width: "100%",
    },
    dateText: {
      marginBottom: theme.spacing(1),
    },
    viewDetailsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      },
}));

const UnlinkQuestionView = ({activity, onConfirm, onReject} : {activity: Activity, onConfirm: () => void, onReject: () => void}) => {
    const {formatMessage} = useIntl();
    const theme = useMfwThemeProvider();
    const classes = useStyles();
    const [showButtons, setShowButtons] = useState(!activity.message.rejected && !activity.message.accepted);

    return (
        <Box border={`2px solid ${theme.mfwMuiWebTheme.palette.primary.light}`} padding='2%' marginTop='2%' borderRadius={5} color={'red'}>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Box display='flex' flexDirection='row'>
                    <Typography variant="body1" style={{marginLeft: 5, marginTop: 5}}>{formatMessage(chatMessages.user_with_email)} {activity.email} {formatMessage(chatMessages.ask_keep_workouts)}</Typography>
                </Box>
            </Box>
            <Box mt={1} className={classes.bubbleContainer}>
                <Box className={classes.flexColumn}>
                    <Typography className={classes.dateText}>{format(activity.createdAt, "Y-MM-dd HH:mm")}</Typography>
                    {!showButtons && (
                        <Box display="flex" justifyContent="flex-end">
                            <Typography style={{ color: theme.mfwMobTheme.primary }}>
                                {activity.message.rejected ? formatMessage(chatMessages.rejected) : formatMessage(chatMessages.accepted)}
                            </Typography>
                        </Box>
                    )}
                    {showButtons && 
                        <Box className={classes.viewDetailsContainer}>
                            <Button variant="text" color="primary" onClick={async () => {onReject(); setShowButtons(false);}}>
                                {formatMessage(chatMessages.reject)}
                            </Button>
                            <Button variant="text" color="primary" onClick={async () => {onConfirm();  setShowButtons(false);}}>
                                {formatMessage(chatMessages.accept)}
                            </Button>
                        </Box>
                    }
                </Box>
            </Box>   
        </Box>   
    )
};
export default UnlinkQuestionView;
