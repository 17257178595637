import React, {Fragment, useCallback, useEffect, useState} from "react";
import SideDrawer, {SideDrawerProperties} from "../SideDrawer";
import ActivityView from "../chat/ActivityView";
import {firestore} from "../../firebase";
import {Activity, Tutorial, User} from "myfitworld-model";
import ChatDialog from "../chat/ChatDialog";
import {useNavigate} from "@reach/router";
import BirthdayNotificationView from "../chat/BirthdayNotificationView";
import { useNotificationsProvider } from "../../providers/NotificationsProvider";
import LoadMoreButton from "../chat/LoadMoreButton";
import { Box, Button, Typography } from "@material-ui/core";
import DeleteUserNotificationView from "../chat/DeleteUserNotificationView";
import { buildUrl, configMainRoutesConsts } from "../../config/route";
import TrainerNotificationView from "../chat/TrainerNotificationView";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { useIntl } from "react-intl";
import globalMessages from '../../messages';
import notificationMessages from './messages';
import componentMessages from "../messages";
import {TutorialInfoDialog} from "../../pages/tutorials/TutorialInfoDialog";
import useEntityList from "../../hooks/useEntityList";
import {tutorialApi} from "../../api/common";
import {getVideoSourceByAppSection} from "../../utils/getVideoSourceTutorial";
import {SectionOptions} from "../users/helpers";
import {useUserProvider} from "../../providers/UserProvider";
import usersApi, { getUser } from "src/api/usersApi";
import { act } from "react-dom/test-utils";
import LoadingSpinner from "../LoadingSpinner";
import UnlinkQuestionView from "../chat/UnlinkQuestionView";
import useKeepClientWorkouts from "src/hooks/ClientWorkouts/useKeepClientWorkouts";
import { l } from "i18n-js";

const  NotificationsDrawer = ({ isOpen, onClose }: Props) => {
  const {user} = useUserProvider();
  const navigate = useNavigate();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [userName, setUserName] = useState<string>();
  const {allNotifications, markAsReadAllNotifications, unreadCount, setLimit, limit, readAllNotifications} = useNotificationsProvider();
  const {formatMessage} = useIntl();
  const [notificationsWithClientData, setNotificationsWithClientData] = useState<NotificationWithClientData[]>([]);
  const [loading, setLoading] = useState(true);
  const {confirmKeepWorkouts, rejectKeepWorkouts} = useKeepClientWorkouts();


  const handleDialogOpen = (userId: string) => {
    firestore.collection('users').doc(userId).get().then(response => {
      setUserId(userId);
      setUserName(`${(response.data() as User).firstName} ${(response.data() as User).lastName}`)
    })
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onViewDetails = useCallback(
    (activity: Activity) => {
      activity.workoutId ?
      navigate(
        buildUrl(
          configMainRoutesConsts.clientStatisticPage,
          [
            activity.threadId,
            activity.workoutId
          ]
        )
      )
       :
       navigate(
        buildUrl(
          configMainRoutesConsts.clientDailyActivityInfo,
          [
            activity.threadId,
            activity.id || ""
          ]
        )
      )
      onClose();
    },
    [onClose, navigate]
  );


  const loadClientsForEachNotification = async () => {
    const updatedNotifications = await Promise.all(
      allNotifications.map(async (notification) => {
        const client = await getUser(notification.threadId);
        return {
          ...notification,
          client,
        } as NotificationWithClientData;
      })
    );
    setNotificationsWithClientData(updatedNotifications);
    setLoading(false);
  };
  

  useEffect(() => {
    if(allNotifications !== undefined){
      loadClientsForEachNotification();
    }
    if(unreadCount > 0){
      markAsReadAllNotifications();
    }
  }, [allNotifications]);
  
  const loadMoreNotifications = () => {
    setLimit((prevState: number) => prevState + 10);
  }

  const {data: tutorials} = useEntityList<Tutorial>(tutorialApi.list);
  const videoSource = getVideoSourceByAppSection(tutorials, SectionOptions[12].id, user?.language);
 
  return (
    <Fragment>
      <SideDrawer isOpen={isOpen} onClose={onClose}>
        <Box display='flex' justifyContent='flex-end'>
          <Box display='flex' flexDirection={'column'} alignItems={'center'}>
            <TutorialInfoDialog title={formatMessage(componentMessages.clients)} videoSource={videoSource}/>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/notification-center');
                onClose();
              }}
            >
              <SettingsOutlinedIcon/>
              <Typography variant="body1" style={{marginLeft: 2}}>{formatMessage(globalMessages.notification_center)}</Typography>
            </Button>
          </Box>
        </Box>
        <div>
          {
            loading && 
            <LoadingSpinner marginTop={20}></LoadingSpinner>
          }
          {allNotifications !== undefined && allNotifications.length === 0 &&
            <Typography variant='h3'>{formatMessage(notificationMessages.no_notifications)}</Typography>
          }
          {notificationsWithClientData.map((activity, index) =>
            activity.type === "BIRTHDAY_NOTIFICATION" ? 
            <BirthdayNotificationView
                activity={activity}
                client={activity.client}
                key={activity.id}
                onSendHappyBirthday={() => handleDialogOpen(activity.threadId)}
            />
            : activity.type === "DELETE_USER" ? 
            (
              <DeleteUserNotificationView 
                activity={activity} 
              />
            ) 
            : activity.type === "TRAINER_NOTIFICATION" ?
            (
              <TrainerNotificationView 
                activity={activity} 
                client={activity.client} 
              />
            )
            : activity.type === "UNLINK_QUESTION" ?
            (
              <UnlinkQuestionView
                activity={activity}
                key={activity.id} 
                onConfirm={() => 
                  {
                    confirmKeepWorkouts(activity);
                    readAllNotifications();
                  }
                } 
                onReject={() => 
                  {
                    if(user?.currentOrganization){
                      rejectKeepWorkouts(activity, user?.currentOrganization);
                      readAllNotifications();
                    }
                  }
                }              
              />
            )
            :
            (
              <ActivityView 
                activity={activity} 
                key={activity.id} 
                onViewDetails={() => onViewDetails(activity)} 
                client={activity.client}
              />
            )
          )
          }
           {
            allNotifications.length >= limit && 
            <LoadMoreButton onPress={loadMoreNotifications} />
          }
        </div>
      </SideDrawer>
      {isOpenDialog && userId && userName &&
      <ChatDialog isOpen={isOpenDialog} handleClose={handleDialogClose} userId={userId} userName={userName}/>
      }
    </Fragment>
  );
};

export default NotificationsDrawer;

interface Props extends SideDrawerProperties {}

const getActivityRef = (id: string) => firestore.collection("activity").doc(id);

export interface NotificationWithClientData extends Activity{
  client: User|undefined;
}