import { Activity } from "myfitworld-model";
import { firestore } from "src/firebase";

 export const updateActivityUnlinkQuestion = async (activity: Activity, action: 'accepted'|'rejected') => {
    const ref = firestore.collection("activity").doc(activity.id);
    activity.message = {
        accepted: action === 'accepted',
        rejected: action === 'rejected'
    };
    await ref.update({
        message: activity.message
    });
 }