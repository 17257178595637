import { Dialog, DialogTitle, DialogActions, Button, Typography, DialogContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import dialogMessages from "./messages";
import { Invitation } from "myfitworld-model";
import { getOrganization } from "src/api/organizationsApi";

const InvitationDialog = ({visible, invitation, onPressConfirm, onPressReject}: InvitationDialogProps) => {
    const {formatMessage} = useIntl();
    const [organization, setOrganization] = useState<string | null>(null);

    useEffect(() => {
        const fetchOrganization = async () => {
            if (invitation) {
                const org = await getOrganization(invitation.organization);
                if(org){
                    setOrganization(org.name || "");   
                }
            }
        };

        fetchOrganization();
    }, [invitation]);

    return(
        <Dialog open={visible}>
            <DialogTitle>
                <Typography variant="subtitle2">{formatMessage(dialogMessages.new_invitation).toUpperCase()}</Typography>
            </DialogTitle>
            <DialogContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <Typography variant="h5">
                    {formatMessage(dialogMessages.invitation_text, {organizationName: organization, role: invitation?.role})}
                </Typography>
                <Typography variant="caption" style={{marginTop: 5}}> 
                    {formatMessage(dialogMessages.invitation_additional_text)}
                </Typography>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button
                    color="secondary"
                    onClick={onPressReject}
                >
                    {formatMessage(dialogMessages.reject)}
                </Button>
                <Button
                    onClick={onPressConfirm}
                    color="primary"
                    autoFocus
                >
                    {formatMessage(dialogMessages.accept)}
                </Button>
            </DialogActions>
      </Dialog>
    );
};
export default InvitationDialog;

interface InvitationDialogProps {
    visible: boolean;
    invitation: Invitation;
    onPressConfirm: () => void,
    onPressReject: () => void,
}