import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  cancel: {
    id: 'dialog.cancel',
    defaultMessage: 'cancel'
  },
  confirm: {
    id: 'dialog.yes',
    defaultMessage: 'yes'
  },
  create_notification: {
    id: 'dialog.create_notification',
    defaultMessage: 'Create notification'
  },
  send_to: {
    id: 'dialog.send_to',
    defaultMessage: 'Send to:'
  },
  enter_notification: {
    id: 'dialog.enter_notification',
    defaultMessage: 'Enter text for the notification'
  },
  required_field: {
    id: 'dialog.required_field',
    defaultMessage: 'This field is required.'
  },
  me: {
    id: 'dialog.notification_me',
    defaultMessage: 'ME'
  },
  me_and_client: {
    id: 'dialog.notification_me_and_client',
    defaultMessage: 'ME AND CLIENT'
  },
  client: {
    id: 'dialog.notification_client',
    defaultMessage: 'CLIENT'
  },
  symbol_plus: {
    id: 'dialog.symbol_plus',
    defaultMessage: '+'
  },
  button_install_icon: {
    id: 'dialog.button_install_icon',
    defaultMessage: 'add'
  },
  add_wl_to_homescreen: {
    id: 'dialog.add_wl_to_homescreen',
    defaultMessage: '+ Add {name} Icon to Home Screen'
  },
  install_icon_instructions: {
    id: 'dialog.install_icon_instructions',
    defaultMessage: 'How to Install Icon on Home Screen?'
  },
  are_you_sure_you_want_to_archive_this_client: {
    id: 'dialog.are_you_sure_you_want_to_archive_this_client',
    defaultMessage: 'Are you sure you want to archive this client?'
  },
  if_your_client_is_archived_you_wont_be_able_to_view_content: {
    id:'dialog.if_your_client_is_archived_you_wont_be_able_to_view_content',
    defaultMessage: "If your client is archived, you won't be able to view content."
  },
  archive: {
    id: 'dialog.archive',
    defaultMessage: 'Archive'
  },
  archiving: {
    id: 'dialog.archiving',
    defaultMessage: 'Archiving'
  },
  to_manage_your_subscription_sign_in_here_with_your_credentials: {
    id: 'dialog.to_manage_your_subscription_sign_in_here_with_your_credentials',
    defaultMessage: 'To manage your subscription sign in here with your credentials.'
  },
  choose_unit_system: {
    id: 'nutrition_plan.choose_unit_system',
    defaultMessage: 'Choose unit system'
  },
  height: {
    id: 'height',
    defaultMessage: 'Height'
  },
  mass: {
    id: 'mass',
    defaultMessage: 'Mass'
  },
  volume: {
    id: 'volume',
    defaultMessage: 'Volume'
  },
  energetic_value: {
    id: 'energetic_value',
    defaultMessage: 'Energetic value'
  },
  speed: {
    id: 'speed',
    defaultMessage: 'Speed'
  },
  length: {
    id: 'length',
    defaultMessage: 'Length'
  },
  body_mass_index: {
    id: 'body_mass_index',
    defaultMessage: 'Body mass index'
  },
  entries_count_by_month: {
    id: 'dialog.entries_count_by_month',
    defaultMessage: 'Entries count by month'
  },
  weight: {
    id: 'workout.weight',
    defaultMessage: 'Weight'
  },
  enable: {
    id: 'mfwnet.enable',
    defaultMessage: 'Enable'
  },
  disable: {
    id: 'mfwnet.disable',
    defaultMessage: 'Disable'
  },
  mfwnetpermission_text: {
    id: 'mfwnet.mfwnetpermission_text',
    defaultMessage: 'By enabling this feautre, you give your clients access to MFWNet - public section where all the trainers and/or gyms, fitness and sports clubs post their public (free) content.'
  },
  mfwnet_permission: {
    id: 'profile.mfwnet_permission',
    defaultMessage: 'MFWNet Permission'
  },
  marketplace_dialog_title: {
    id: 'marketplace.dialog.title',
    defaultMessage: 'Marketplace Info'
  },
  marketplace_dialog_content: {
    id: 'marketplace.dialog.content',
    defaultMessage: 'Buying Content is only available to Exercisers. You can only Preview Content. If you want to Buy Content, please register with different Account as an Exerciser (Free, Organization\'s Client, or a PREMIUM Account).'
  },
  marketplace_trainer_info: {
    id: 'marketplace.trainer.info',
    defaultMessage: 'Editing Content is not allowed on your Marketplace page. If you want to edit your Content, please go to your Content section.'
  },
  personal_data: {
    id: 'profile.personal_data',
    defaultMessage: 'Personal Data'
  },
  about_me: {
    id: 'profile.about_me',
    defaultMessage: 'About Me'
  },
  new_invitation: {
    id: 'new_invitation',
    defaultMessage: 'New Invitation'
  },
  invitation_text: {
    id: 'invitation_text',
    defaultMessage: 'You have an invitation to join organization {organizationName} as {role}'
  },
  invitation_additional_text: {
    id: 'invitation_additional_text',
    defaultMessage: 'You will be archived in your current organization.'
  },
  reject: {
    id: 'reject',
    defaultMessage: 'Reject'
  },
  accept: {
    id: 'invitation.info_page.button_accept',
    defaultMessage: 'Accept'
  }
})

export default messages;
