import { OrganizationUser } from "myfitworld-model";
import safeInvoke from "../utils/safeInvoke";
import { subDays } from "date-fns";

export function sortUsersByName(clients: OrganizationUser[]): OrganizationUser[] {
    const sortedClientsByName: OrganizationUser[] = clients.sort((userA, userB) => {
      const nameA = (userA.user?.lastName + ' ' + userA.user?.firstName).toLowerCase();
      const nameB = (userB.user?.lastName + ' ' + userB.user?.firstName).toLowerCase();
  
      return nameA.localeCompare(nameB);
    });
  
    return sortedClientsByName;
}
  export function queryByName(clients: OrganizationUser[], nameSearchQuery: string) {
    const nameSearchQueryLower = nameSearchQuery.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();      
    const queriedUsersByName: OrganizationUser[] = clients
      .filter((client) => {
        const clientName: string = ((client.user?.firstName || "") + " " + (client.user?.lastName || "")).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        return clientName.trim() !== "";
      })
      .filter((client) => {
        const clientName: string = ((client.user?.firstName || "") + " " + (client.user?.lastName || "")).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        return (
          clientName.startsWith(nameSearchQueryLower) ||
          (client.user?.firstName || "").normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().startsWith(nameSearchQueryLower) ||
          (client.user?.lastName || "").normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().startsWith(nameSearchQueryLower)
        );
      });

    return queriedUsersByName;
  }
  export function queryByLastSignIn(clients: OrganizationUser[], dateTimeQuery: string, allClients: OrganizationUser[]) {
    const queriedClientsByLastSignIn: OrganizationUser[] = clients.filter((user) => {
      switch (dateTimeQuery) {
        case "ALL":
          return allClients;
        case "DAYS_1":
          return safeInvoke("toDate")(user?.user?.onlineStateLastChanged) > subDays(new Date(), 1);
        case "DAYS_7":
          return safeInvoke("toDate")(user?.user?.onlineStateLastChanged) > subDays(new Date(), 7);
        case "DAYS_10":
          return safeInvoke("toDate")(user?.user?.onlineStateLastChanged) > subDays(new Date(), 10);
        case "DAYS_30":
          return safeInvoke("toDate")(user?.user?.onlineStateLastChanged) > subDays(new Date(), 30);
        case "DAYS_60":
          return safeInvoke("toDate")(user?.user?.onlineStateLastChanged) > subDays(new Date(), 60);
        case "DAYS_90":
          return safeInvoke("toDate")(user?.user?.onlineStateLastChanged) > subDays(new Date(), 90);
        default:
          return allClients;
      }
    });
    return queriedClientsByLastSignIn;
  }